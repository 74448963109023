import React, { CSSProperties, ReactNode } from 'react';

interface Props {
  description: string;
}

export default class HomePage extends React.Component<Props> {
  private style: CSSProperties = {
    margin: '32px',
  };

  render(): ReactNode {
    return (
      <div style={this.style}>
        <p>Hey there. My name is Jeff Hudson.</p>
        <p>I&apos;m a contract software engineer and entrepreneur.</p>
        <p>
          Check out&nbsp;
          <a href="https://www.linkedin.com/in/jeffhudson155/" target="_blank" rel="noopener noreferrer">
            my LinkedIn
          </a>
          &nbsp;to see more about what I'm up to.
        </p>
        <p>
          If you&apos;d like to get in touch with me, shoot me an email at&nbsp;
          <a href="mailto:jhudson@jhudson.ca">jhudson@jhudson.ca</a>.
        </p>
      </div>
    );
  }
}
