import React, { CSSProperties, ReactNode } from 'react';

class NotFoundPage extends React.Component {
  private style: CSSProperties = {
    margin: '32px',
  };

  render(): ReactNode {
    return (
      <div style={this.style}>
        <p>The page you were looking for does not exist.</p>
        <p>
          Try going back to the <a href="/">homepage</a>.
        </p>
      </div>
    );
  }
}

export default NotFoundPage;
